import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMpdTableFilterColumnValReducer,
  updateMpdTableFilterReducer,
  updateMpdTableFilterValueReducer,
  createMpdTableFilterPending,
  createMpdTableFilterRejected,
  updateMpdTableFilterGroupReducer,
  deleteOneMpdTableFilterReducer,
  clearAllMpdTableFilterReducer,
  resetMpdTableValueReducer,
  createMpdTableExportTaskFulfilled,
  createMpdTableExportTaskRejected,
  updateMpdTablePageNumberReducer,
  setMpdTableExportButtonStateReducer,
  createMpdTableFulfilled,
  clearMpdTableHeadersStateReducer,
  clearFilterDataReducer,
  setFilterDataReducer,
} from './mpdEmtlTableReducers';
import {
  createMpdTableExportTask, createMpdTable, createMpdTableFilter,
} from './mpdEmtlTableAsyncThunk';

export const initialState: mpdTableState = {
  mpdTableDataRowFilteredDataType: [],
  mpdTableHeaders: { mpd: [], appendix: [] },
  mpdTableFilterColType: [],
  mpdTableDataRowCountType: 0,
  mpdTableDataPageSizeType: 50,
  mpdTableDataPageNumberType: 1,
  mpdTableColumnType: 'mpd_task_revision_code',
  mpdTableColumnDataType: '',
  mpdTableColumnDataOptionType: [],
  mpdTableColSanitizedOptionType: [],
  mpdTableFilterGroup: [],
  mpdTableExportLoading: false,
  mpdTableExportFile: '',
  mpdTableExportId: '',
  mpdTableExportButton: true,
  loading: false,
  error: '',
  filterData: '',
};

const emtlTableSlice = createSlice({
  name: 'mpdTable',
  initialState,
  reducers: {
    updateMpdTableFilterValue: updateMpdTableFilterValueReducer,
    updateMpdTableFilter: updateMpdTableFilterReducer,
    updateMpdTableFilterGroup: updateMpdTableFilterGroupReducer,
    resetMpdTableValue: resetMpdTableValueReducer,
    deleteOneMpdTableFilter: deleteOneMpdTableFilterReducer,
    clearAllMpdTableFilter: clearAllMpdTableFilterReducer,
    updateMpdTablePageNumber: updateMpdTablePageNumberReducer,
    updateMpdTableExportButtonState: setMpdTableExportButtonStateReducer,
    clearMpdTableHeaders: clearMpdTableHeadersStateReducer,
    clearFilterData: clearFilterDataReducer,
    setFilterData: setFilterDataReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createMpdTableFilter().fulfilled, fetchMpdTableFilterColumnValReducer);
    builder.addCase(createMpdTable().pending, createMpdTableFilterPending);
    builder.addCase(createMpdTable().fulfilled, createMpdTableFulfilled);
    builder.addCase(createMpdTable().rejected, createMpdTableFilterRejected);
    builder.addCase(createMpdTableExportTask().fulfilled, createMpdTableExportTaskFulfilled);
    builder.addCase(createMpdTableExportTask().rejected, createMpdTableExportTaskRejected);
  },
});

export const {
  updateMpdTableFilterValue,
  updateMpdTableFilter,
  updateMpdTableFilterGroup,
  resetMpdTableValue,
  deleteOneMpdTableFilter,
  clearAllMpdTableFilter,
  updateMpdTablePageNumber,
  updateMpdTableExportButtonState,
  clearFilterData,
  setFilterData,
  clearMpdTableHeaders,
} = emtlTableSlice.actions;
export default emtlTableSlice;
