/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MPD_TABLE_EXPORT_TASK_API_PATH, CREATE_MPD_TABLE_EXPORT_TASK, CREATE_MPD_TABLE, FETCH_MPD_TABLE,
  MPD_TABLE_API_PATH, FETCH_TADA_EMTL_FILTER_VALUE, CREATE_TADA_EMTL_FILTER, MPD_TABLE_FILTER_API_PATH, GET_FILE_URL,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';
import { FETCH_MPD_REPOSITORY_EXPORT_FILE_API_PATH } from '../mpdRepositoryModel/constants';
import emtlTableSlice from './mpdEmtlTableSlice';

export const createMpdTableFilter: any = (colName: string, colVal: string, msnReg: string[], modName: string, filterGroup: EmtlTableGroupAction[]) => {
  const customAction = {
    sliceName: FETCH_TADA_EMTL_FILTER_VALUE,
    actionType: CREATE_TADA_EMTL_FILTER,
  };
  const apiRequestObj = {
    path: MPD_TABLE_FILTER_API_PATH,
    method: 'post' as Methods,
    body: {
      column_name: colName,
      column_value: colVal,
      module_name: modName,
      msn_registration: msnReg,
      filter_group: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdTable: any = (moduleName: string, acMsnRegNo: string[], filterGroup: [], pageSize: number, pageNumber: number) => {
  const customAction = {
    sliceName: FETCH_MPD_TABLE,
    actionType: CREATE_MPD_TABLE,
  };

  const apiRequestObj = {
    path: `${MPD_TABLE_API_PATH}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    method: 'post' as Methods,
    body: {
      module_name: moduleName,
      msn_registration: acMsnRegNo,
      filter_group: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdTableExportTask: any = (
  acProgram: string,
  customizationModule: string,
  acSeries: string[],
  acMsnRegNo: string[],
) => {
  const customAction = {
    sliceName: emtlTableSlice.name,
    actionType: CREATE_MPD_TABLE_EXPORT_TASK,
  };

  const apiRequestObj = {
    path: MPD_TABLE_EXPORT_TASK_API_PATH,
    method: 'post' as Methods,
    body: {
      customizationModule,
      filterValue: {
        maintenance_program: acProgram,
        ac_series: acSeries,
        msn_registration: acMsnRegNo,
        filter_group: [],
      },
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const getFileURL: any = (fileKey: string, fileType: string) => {
  const customAction = {
    sliceName: emtlTableSlice.name,
    actionType: GET_FILE_URL,
  };
  const apiRequestObj = {
    path: `${FETCH_MPD_REPOSITORY_EXPORT_FILE_API_PATH}?export-task-id=${fileKey}&file-type=${fileType}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
