import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import { IconButton, Button, Chip } from '@airbus/components-react';
import { Close, Help } from '@airbus/icons/react';
import './HelpCenterComponent.scss';
import { UserguideHelperLinks } from '../../utils/UserguideLinks/UserguideHelperLinks';
import { appContext } from '../../utils/context/userContext';
import * as pkg from '../../../package.json';

// to get version from package.json
const { version } = pkg;
const formattedVersion = version.split('.').slice(0, 2).join('.');
const HelpIconComponent = () => {
  const [isHelpIconEnable, setIsHelpIconEnable] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { userType } = useContext(appContext);

  const handleHelpIcon = () => {
    setIsHelpIconEnable(!isHelpIconEnable);
  };

  const handleCloseHelpIcon = () => {
    setIsHelpIconEnable(false);
  };
  useEffect(() => {
    // Checks if a click event occurred outside of the referenced elements and close popup
    const handleClickOutside = (event: MouseEvent) => {
      if ((ref.current && !ref.current.contains(event.target as Node))) {
        handleCloseHelpIcon();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="help-icon-popup" ref={ref}>
      <IconButton variant="ghost" aria-label="Help" onClick={handleHelpIcon}>
        <Help />
      </IconButton>
      {isHelpIconEnable && (
        <div className="help-popup">
          <div className="help-popup-content">
            <a href={UserguideHelperLinks(userType)} target="_blank" rel="noopener noreferrer">
              <Button variant="ghost" className="help-popup-link">
                Help Center
              </Button>
            </a>
            <IconButton variant="ghost" className="help-popup-close" size="xsmall" onClick={handleCloseHelpIcon}>
              <Close />
            </IconButton>
          </div>
          <div className="help-popup-version">
            <p>Version</p>
            <Chip label={`App v${formattedVersion}`} className="help-popup-chip" />
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpIconComponent;
