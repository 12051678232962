import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
} from '@airbus/components-react';
import './MpdRepository.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MpdRepositoryFilterPanel from '../../components/MpdRepositoryFilterPanel/MpdRepositoryFilterPanel';
import BannerComponent from '../../components/Shared/BannerComponent/BannerComponent';
import { bannerLocationMap } from '../../models/bannerModel/constants';
import EmtlCardsContainer from '../../components/MpdRepository/ProgCustomization/EmtlCardsContainer';
import BuildListContainer from '../../components/MpdRepository/ProgAnalysis/BuildListContainer';
import { MpdRepoPanelSubItem } from './mpdRepositoryUtils';
import MpdRepositorySelectedFileBar from '../../components/MpdRepositorySelectedFileBar/MpdRepositorySelectedFileBar';
import {
  updateRepositoryFilter,
  updateEmtlPageNumber,
  updateFilterOptions,
  updateEmtlRowsPerPage,
  updateRepositoryTab,
  setFilterPanelOpen,
  updateRepositoryModule,
} from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';

export const componentToDisplay = (location: string) => {
  if (location && location.includes('/program-analysis')) {
    return {
      title: 'Saved results',
      key: 'pa-builds-item',
      componentToRender: <BuildListContainer />,
      disabled: false,
      path: 'analysis-list',
      val: '',
    };
  }
  return {
    title: 'EMTL',
    key: 'pc-emtl-item',
    componentToRender: <EmtlCardsContainer />,
    disabled: false,
    path: 'emtl',
    val: 'EMTL',
  };
};

const MpdRepository = function Repository() {
  const navigate = useNavigate();
  const loc = useLocation();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMenu, setSelectedMenu] = useState<MpdRepoPanelSubItem>(componentToDisplay(loc.state));
  const {
    RepositoryEMTLTabType: tab, RepositoryFilterPanalOpen: open,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);

  /*
    A clean-up when exiting the container. Instead of re-setting these params when mounting the component,
    we take advantage of the fact that it's already set-up in the store in the first place and reset it to
    original values only when exiting. This saves an API call in the subsequent component <EmtlCardsContainer />
   */
  useEffect(() => {
    if (loc.pathname.includes('/repository/emtl/view/')) {
      navigate(loc.pathname);
      dispatch(setFilterPanelOpen(false));
    } else {
      dispatch(updateRepositoryTab(selectedMenu.val));
      dispatch(updateRepositoryModule(selectedMenu.val));
      navigate(`/repository/${selectedMenu.path}`);
      dispatch(setFilterPanelOpen(true));
    }
    return () => {
      dispatch(updateRepositoryFilter([]));
      dispatch(updateEmtlRowsPerPage(10));
      dispatch(updateEmtlPageNumber(1));
      dispatch(updateRepositoryTab('EMTL'));
      dispatch(updateFilterOptions({ message: 'update filter options', data: { column_name: 'issueNumber', column_value: [] } }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <BannerComponent location={bannerLocationMap.REPOSITORY_MODULE} />
      <Row className="repository-list-cls">
        <>
          <Col className={open ? 'filter-panel-container' : 'closed-filter-panel-container'}>
            <MpdRepositoryFilterPanel />
          </Col>
          <Outlet />
        </>
      </Row>
      <Row>
        {(loc.pathname.includes('/repository/emtl') && tab === 'EMTL') && <MpdRepositorySelectedFileBar />}
      </Row>
    </>
  );
};

export default MpdRepository;
