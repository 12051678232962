import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, CardContent, CardHeader, IconButton, Modal, Stack, Banner, Autosave,
} from '@airbus/components-react';
import { CropFree, Close } from '@airbus/icons/react';
import { defaultChartOptions } from '../../utils/chartUtils/pieDefaultOptionUtil';
import { costCategories, COLOR_CODES } from '../../utils/chartUtils/chartLib';
import { getChartComponent } from '../../utils/chartUtils/chart.component';
import { LOCALES } from '../../assets/locale';
import { useAppSelector } from '../../store/hooksTypes';
import { MPD_TASK_REFERENCE } from '../../models/programAnalysis/constants';
import { maintenanceTask, scheduleAndUnschedule } from '../../models/programAnalysis/programAnalysisTypes';
import './EconomicAssessment.scss';

/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatterLabel = (value: any, context: any) => {
  let percentageToDisplay = value;
  let dollarToDisplay;
  if (value !== 'N/A') {
    percentageToDisplay = `${value}%`;
    if (value === context.dataset.scheduledCostPercent) dollarToDisplay = `($${context.dataset.scheduledCostDollar.toFixed(2)})`;
    else if (value === context.dataset.unScheduledCostPercent) dollarToDisplay = `($${context.dataset.unScheduledCostDollar.toFixed(2)})`;
    else dollarToDisplay = `($${Number.parseFloat('0').toFixed(2)})`;
  } else {
    dollarToDisplay = '';
  }
  return ` ${percentageToDisplay} ${dollarToDisplay}`;
};

const getChartData = (scheduleAndUnscheduleData: scheduleAndUnschedule) => {
  const {
    scheduledCostPercent, unScheduledCostPercent, scheduledCostDollar, unScheduledCostDollar,
  } = scheduleAndUnscheduleData;
  const labels = costCategories;
  const dataValues = [scheduledCostPercent || 'N/A', unScheduledCostPercent || 'N/A'];
  const backgroundColor = COLOR_CODES;
  const datasets = [
    {
      label: '',
      data: dataValues,
      backgroundColor,
      scheduledCostPercent,
      unScheduledCostPercent,
      scheduledCostDollar,
      unScheduledCostDollar,
    },
  ];
  return { datasets, labels };
};

const chartOpts = {
  ...defaultChartOptions,
  plugins: {
    ...defaultChartOptions.plugins,
    datalabels: {
      ...defaultChartOptions.plugins.datalabels,
      formatter: formatterLabel,
      labels: {
        title: {
          font: {
            size: 15,
            weight: 'bold',
          },
        },
      },
    },
    title: {
      text: ['Total cost per A/C per year (US $)'],
      display: true,
      position: 'bottom',
    },
    legend: {
      position: 'bottom',
    },
  },
};

const EconomicAssessment: React.FC = function EconomicAssessment() {
  const taskObj = useAppSelector((state) => state.programAnalysis.tasks);
  const { taskNumber } = useParams();
  const [zoomState, setZoomState] = useState(false);
  const handleZoom = () => {
    setZoomState(!zoomState);
  };

  const PieChart = getChartComponent('pie');

  const [bannerState, setBannerState] = useState(true);
  const findObj = useMemo(() => {
    return (taskObj.data as maintenanceTask[]).find((item) => item[MPD_TASK_REFERENCE] === taskNumber) as maintenanceTask;
  }, [taskObj.data, taskNumber]);
  const displayComponent = () => {
    const {
      scheduled_cost_dollar: tscd, scheduled_cost_percent: tscp, unscheduled_cost_percent: tucp, unscheduled_cost_dollar: tucd, nb_pre_idntfd_ois_tada: ois, source_document_type: sourceDocType,
    } = findObj ?? {};

    const tadaSummaryChart = () => (
      getChartData({
        scheduledCostPercent: tscp,
        unScheduledCostPercent: tucp,
        scheduledCostDollar: tscd,
        unScheduledCostDollar: tucd,
      })
    );
    const cond_MRB_SYSTEM_SAFETY = (
      sourceDocType && (sourceDocType.includes('MRB 5')
      || sourceDocType.includes('MRB 8')
      || /MRB ([a-zA-Z0-9]{1}), 8/.test(sourceDocType)
      ));
    const getDisclaimer = () => {
      let disclaimerText;
      if (parseInt(ois, 10) < 5) disclaimerText = LOCALES.disclaimer_ois;
      else disclaimerText = LOCALES.new_disclaimer_general;
      return disclaimerText;
    };
    const noBanner = () => tscd && tucd;
    const banner = () => (
      bannerState ? (
        <Banner
          variant="warning"
          onClose={() => {
            setBannerState(false);
          }}
        >
          <tbody className="disclaimer-text">
            <tr>
              {getDisclaimer()}
              {' '}
            </tr>
          </tbody>
        </Banner>
      ) : null
    );
    return (
      <>
        <Stack spacing="2-x" wrapChildren className="disclaimer">
          {noBanner() ? null : banner()}
        </Stack>
        {cond_MRB_SYSTEM_SAFETY ? (
          <p className="no-eco-assessment" />
        ) : (
          <Card className="chart-card">
            <CardHeader
              className="card-header"
              title=" Economical Assessment Summary"
              action={(
                <IconButton variant="ghost" onClick={handleZoom} aria-label="zoom-modal">
                  <CropFree />
                </IconButton>
              )}
            />
            <CardContent className="card-content">
              <PieChart
                className="pie-chart"
                data={tadaSummaryChart()}
                options={chartOpts}
              />
            </CardContent>
          </Card>
        )}
        <Modal aria-label="modal-task-piechart" aria-labelledby="modal-title" aria-describedby="modal-description" open={zoomState} onClose={handleZoom} className="eco-assess-modal">
          <Card className="card">
            <CardHeader
              className="card-header"
              title=" Economical Assessment Summary"
              action={(
                <IconButton variant="ghost" onClick={handleZoom} aria-label="close-modal">
                  <Close />
                </IconButton>
              )}
            />

            <CardContent className="card-content">
              <PieChart
                className="pie-chart"
                data={
                tadaSummaryChart()
                }
                options={chartOpts}
              />
            </CardContent>
          </Card>
        </Modal>
      </>
    );
  };

  return (
    <div className="eco-assessment">
      {taskObj.fetchInfo.loading ? (
        <div className="loader-center">
          <Autosave> </Autosave>
        </div>
      ) : (
        displayComponent()
      )}
    </div>
  );
};
export default EconomicAssessment;
