export const issueDataInitialState = [
  [
    {
      title: 'Issue',
      value: '',
    },
    {
      title: 'Updated on',
      value: '',
    },
  ],
];

export const establishedFromDataInitialState = [
  [
    {
      title: 'MPD Revision',
      value: '',
    },
    {
      title: 'Revision Date',
      value: '',
    },
  ],
];
