import React, { useEffect, useState } from 'react';
import {
  Select, Button, ComboBox, Row, Col,
} from '@airbus/components-react';
import { createMpdTableFilter } from '../../models/mpdEmtlTableModel/mpdEmtlTableAsyncThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import {
  updateMpdTableFilter,
  updateMpdTableFilterGroup,
  updateMpdTableFilterValue,
  setFilterData,
  clearFilterData,
} from '../../models/mpdEmtlTableModel/mpdEmtlTableSlice';
import { SearchableColumns } from '../../models/programAnalysis/programAnalysisTypes';
import { debounceUtil } from '../../utils/commonUtils/debounce/debounce';

type MpdTableSearchProps = {
  columnsOptions: SearchableColumns[];
};

const MpdTableSearch = function MpdTableSearch(props: MpdTableSearchProps) {
  const dispatch = useAppDispatch();
  const {
    mpdTableColumnDataOptionType: emtlColOption, mpdTableColSanitizedOptionType: emtlSanitizedColOption,
    mpdTableFilterColType: emtlFilter, mpdTableColumnType: emtlSelectedCol,
    mpdTableColumnDataType: emtlSearchSelectedData, mpdTableFilterGroup: filterGroup, filterData,
  } = useAppSelector((state: RootState) => state.mpdTable);
  const {
    AircraftMsnRnoType: emtlMsnReg,
    ModuleName: selectedModule,
  } = useAppSelector((state: RootState) => state.mpdDrawer);
  const { columnsOptions } = props;
  const [select, setSelect] = useState(columnsOptions[0]?.value || '');
  const [searchButton, setSearchButton] = useState(false);

  useEffect(() => {
    const selectedColumn = columnsOptions.filter((item) => item.id === emtlSelectedCol)[0]?.value;
    setSelect(selectedColumn);
  }, [emtlSelectedCol, columnsOptions]);

  const searchInputHandler = function handler(selectedVal: string) {
    dispatch(createMpdTableFilter(emtlSelectedCol, selectedVal, emtlMsnReg, selectedModule, filterGroup)());
  };

  // Adding some mili seconds of delay and waiting for user input
  const debounce = debounceUtil(600);
  return (
    <Row className="table-search-cls">
      <Col sm={2} md={2} lg={2} xl={2} xxl={2} className="mpd-table-search-legend">Filter by</Col>
      <Col sm={4} md={4} lg={4} xl={4} xxl={4}>
        <Select
          style={{ maxWidth: '200px' }}
          aria-label="Select an option"
          placeholder="Select an option"
          value={select}
          options={columnsOptions}
          onChange={(event) => {
            const selectedCol = event?.target.value;
            const foundryCol = columnsOptions.filter((item) => item.value === selectedCol)[0].id;
            dispatch(updateMpdTableFilter(foundryCol));
            setSelect(selectedCol);
            setSearchButton(false);
            dispatch(clearFilterData());
          }}
        />
      </Col>
      <Col style={{ padding: '0px 13px' }} sm={4} md={4} lg={4} xl={4} xxl={4}>
        <ComboBox
          aria-label="mpdTableFilter"
          placeholder="Start Typing"
          style={{ minWidth: '100px' }}
          options={emtlSanitizedColOption}
          value={filterData}
          onChange={(val) => {
            /* istanbul ignore else */
            if (typeof val === 'string') {
              const columnVal = emtlSanitizedColOption.indexOf(val);
              dispatch(updateMpdTableFilterValue(emtlColOption[columnVal]));
              dispatch(setFilterData(val));
              setSearchButton(val.length !== 0);
            }
          }}
          onInput={async (ev) => {
            const keySize = emtlFilter.includes(select) ? 0 : 2;
            const selectedInput = (ev?.target as HTMLInputElement).value;
            if (selectedInput.length > keySize) {
              await debounce();
              searchInputHandler(selectedInput);
            }
          }}
        />
      </Col>
      <Col sm={2} md={2} lg={2} xl={2} xxl={2} style={{ paddingTop: '5px' }}>
        <Button
          variant="primary"
          size="small"
          disabled={!searchButton}
          onClick={() => {
            const selectedFilter = filterGroup.filter((payload) => payload.filter_column !== emtlSelectedCol);
            const addSelectedFilter = selectedFilter.concat({ filter_column: emtlSelectedCol, filter_value: emtlSearchSelectedData });
            dispatch(updateMpdTableFilterGroup(addSelectedFilter));
            dispatch(clearFilterData());
            setSearchButton(false);
          }}
        >
          Search
        </Button>
      </Col>
    </Row>
  );
};

export default MpdTableSearch;
