import React from 'react';
import HomePage from '../HomePage/HomePage';
import TaskReportAnalysis from '../../components/TaskReportAnalysis/TaskReportAnalysis';
import TaskReport from '../TaskReport/TaskReport';
import UnscheduledEvents from '../../components/UnscheduledEvents/UnscheduledEvents';
import MpdCustomisation from '../MpdCustomisation/MpdCustomisation';
import DummyComponent from '../../components/Shared/DummyComponent/DummyComponent';
import EconomicAssessment from '../../components/EconomicAssessment/EconomicAssessment';
import ProgramAnalysis from '../ProgramAnalysis/ProgramAnalysis';
import MpdRepository from '../MpdRepository/MpdRepository';
import PAnalysis from '../../components/PAnalysisComponent/PAnalysis';
import EmtlCardsContainer from '../../components/MpdRepository/ProgCustomization/EmtlCardsContainer';
import BuildListContainer from '../../components/MpdRepository/ProgAnalysis/BuildListContainer';
import EmtlDeltaViewContainer from '../../components/MpdRepository/EmtlDeltaView/EmtlDeltaViewContainer';
import EmtlDeltaViewSOC from '../../components/MpdRepository/EmtlDeltaViewSOC/EmtlDeltaViewSOC';
import PlannedMaintenanceServicesComponent from '../../components/PlannedMaintenanceServices/PlannedMaintenanceServicesComponent';

import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';

export type RoutesConfig = {
  path: string;
  element: JSX.Element;
  childRoute?: RoutesConfig[];
  index?: boolean;
};

const taskReportAnalysisRoute: RoutesConfig[] = [
  {
    path: ':filterId/task-number/:taskNumber',
    element: <TaskReportAnalysis />,
  },
  {
    path: 'unscheduled-events',
    element: <UnscheduledEvents />,
  },
  {
    path: 'economic-assessment',
    element: <EconomicAssessment />,
  },
  {
    path: 'prediction',
    element: <DummyComponent dummyText="prediction ui" />,
  },
];

/** Protected Component applied at the root level will apply to its child route as well
 *  But it can also be applied at the child level directly without in the root level
 */
export const routes: RoutesConfig[] = [
  {
    path: 'program-analysis',
    element:
  <ProtectedComponent permissionFor="ProgramAnalysis">
    <ProgramAnalysis />
  </ProtectedComponent>,
    childRoute: [{
      index: true,
      path: '',
      element: <PAnalysis />,
    },
    {
      path: 'task-report',
      element: <TaskReport />,
      childRoute: taskReportAnalysisRoute,
    },
    {
      path: ':id',
      element: <PAnalysis />,
    }],
  },
  {
    path: 'srs',
    element: <DummyComponent dummyText="srs ui" />,
  },
  {
    path: 'mpd-customise',
    element: <MpdCustomisation />,
  },
  {
    path: 'planning-analysis',
    element: <DummyComponent dummyText="Scheduled Maintenance planning UI" />,
  },
  {
    path: 'repository',
    element:
  <ProtectedComponent permissionFor="ProgramCustomizationRepository">
    <MpdRepository />
  </ProtectedComponent>,
    childRoute: [
      {
        path: 'emtl',
        element: <EmtlCardsContainer />,
      },
      {
        path: 'otr',
        element: <EmtlCardsContainer />,
      },
      {
        path: 'analysis-list',
        element: <BuildListContainer />,
      },
      {
        path: 'emtl/view/:id',
        element: <EmtlDeltaViewContainer />,
      },
      {
        path: 'emtl/view/soc/:id',
        element: <EmtlDeltaViewSOC />,
      },
    ],
  },
  {
    path: 'planned-maintenance-services',
    element: <PlannedMaintenanceServicesComponent />,
  },
  {
    path: '/',
    element: <HomePage />,
  },
];
