import { initialState } from './mpdEmtlTableSlice';

// Filter coloumn dropdown action
export const updateMpdTableFilterReducer = (state: mpdTableState, action: emtlTableFilterColAction) => ({
  ...state,
  mpdTableColumnType: action.payload,
  mpdTableColumnDataOptionType: [],
  mpdTableColSanitizedOptionType: [],
});

// Search dropdown action
export const updateMpdTableFilterValueReducer = (state: mpdTableState, action: emtlTableFilterColAction) => ({
  ...state,
  mpdTableColumnDataType: action.payload,
});

// Reset EMTL table data
export const resetMpdTableValueReducer = (state: mpdTableState) => ({
  ...state,
  loading: false,
  mpdTableDataRowFilteredDataType: [],
  mpdTableDataRowCountType: 0,
  error: '',
});

// Set the stae of emtl export button
export const setMpdTableExportButtonStateReducer = (state: mpdTableState, action: emtlTableExportButtonAction) => ({
  ...state,
  mpdTableExportButton: action.payload,
});

// Async reducers
// Search dropdown action
export const fetchMpdTableFilterColumnValReducer = (state: mpdTableState, action: emtlTableFilterFullfilledAction) => ({
  ...state,
  mpdTableColumnDataOptionType: action.payload.data.column_value,
  mpdTableColSanitizedOptionType: action.payload.data.column_value.map((val: string) => (val.replace('\u00a0', ' ')).replace(/[\n\r]/g, ' ')),
});
// Async reducers
export const createMpdTableFilterPending = (state: mpdTableState) => ({
  ...state,
  loading: true,
});

export const createMpdTableFilterFulfilled = (state: mpdTableState, action: CreateMpdEmtlTableFilterFulfilledAction) => ({
  ...state,
  loading: false,
  mpdTableDataRowFilteredDataType: action.payload.data.emtl_data as EmtlTableType[],
  mpdTableFilterColType: action.payload.data.filter_data as string[],
  mpdTableDataRowCountType: action.payload.totalHits,
  error: '',
});

export const createMpdTableFulfilled = (state: mpdTableState, action: CreateMpdTableFulfilledAction) => ({
  ...state,
  loading: false,
  mpdTableDataRowFilteredDataType: action.payload.data.records as EmtlTableType[],
  mpdTableHeaders: state.mpdTableHeaders.mpd.length === 0 ? action.payload.data.headers : state.mpdTableHeaders,
  mpdTableFilterColType: action.payload.data.filter_data as string[],
  mpdTableDataRowCountType: action.payload.totalHits,
  error: '',
});

export const createMpdTableFilterRejected = (state: mpdTableState, action: CreateMpdEmtlTableFilterRejectedAction) => ({
  ...state,
  loading: false,
  mpdTableDataRowFilteredDataType: [],
  error: action.payload || action.error?.message,
});

export const updateMpdTableFilterGroupReducer = (state: mpdTableState, action: emtlFilterGroup) => ({
  ...state,
  mpdTableFilterGroup: action.payload,
});

export const deleteOneMpdTableFilterReducer = (state: mpdTableState, action: emtlFilterGroup) => ({
  ...state,
  mpdTableFilterGroup: state.mpdTableFilterGroup.filter((payload) => payload.filter_column !== action.payload[0].filter_column),
});

export const clearAllMpdTableFilterReducer = (state: mpdTableState, action: emtlTableFilterColAction) => ({
  ...state,
  mpdTableColumnType: action.payload === 'mpdSubmit' ? 'mpd_task_revision_code' : action.payload,
  mpdTableFilterGroup: [],
  mpdTableColumnDataOptionType: action.payload === 'mpdSubmit' ? [] : state.mpdTableColumnDataOptionType,
  mpdTableColSanitizedOptionType: action.payload === 'mpdSubmit' ? [] : state.mpdTableColSanitizedOptionType,
});

export const createMpdTableExportTaskFulfilled = (state: mpdTableState, action: emtlTableExportTask) => ({
  ...state,
  mpdTableExportId: action.payload.data.taskId,
});

export const createMpdTableExportTaskRejected = (state: mpdTableState) => ({
  ...state,
  mpdTableExportId: 'error',
});

export const updateMpdTablePageNumberReducer = (state: mpdTableState, action: emtlTablePageNumberAction) => ({
  ...state,
  mpdTableDataPageNumberType: action.payload as number,
});

// reducer to set table header to initial state
export const clearMpdTableHeadersStateReducer = (state: mpdTableState) => ({
  ...state,
  mpdTableHeaders: {
    ...initialState.mpdTableHeaders,
  },
});

//  reducer to set the filter value of EFFECTIVE MAINTENANCE TASK
export const setFilterDataReducer = (state: mpdTableState, action: emtlTableFilterDataAction) => (
  { ...state, filterData: action.payload }
);

//  reducer to clear the filter value of EFFECTIVE MAINTENANCE TASK
export const clearFilterDataReducer = (state: mpdTableState) => ({
  ...state, filterData: '',
});
