import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Chip, MenuButton, IconButton, Input,
  Tooltip,
} from '@airbus/components-react';
import {
  Refresh, Delete, Check, Edit,
  LockOutline,
  Sync,
} from '@airbus/icons/react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooksTypes';
import {
  deleteBuildData,
  exportDataToSharedSpace,
  fetchPARepositoryData,
  retryBuildData,
  updateTitle,
} from '../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import { POSSIBLE_BUILD_STATUS } from '../../models/programAnalysis/constants';
import DialogComponent from '../Shared/DialogComponent/DialogComponent';
import {
  MPD_DELETE_PRIMARY_BUTTON_NAME, MPD_DELETE_SECONDARY_BUTTON_NAME, ANALYSIS_DELETE_TITLE, ANALYSIS_DELETE_MESSAGE,
} from '../Shared/DialogComponent/Constants';
import { createToast } from '../../models/ToastModel/toastSlice';
import { clearBuild } from '../../models/programAnalysis/programAnalysisSlice';
import { RebuildPollingInterval } from '../TaskReportAnalysis/constants';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { formatRepositoryDate } from './MpdRepositoryUtil';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import { bannerLocationMap, REPO_FILE_DELETE_FAILED_PA, REPO_FILE_DELETE_SUCCESS_PA } from '../../models/bannerModel/constants';

import './MpdRepositoryAnalysisCard.scss';
import { featureToggleConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { appContext } from '../../utils/context/userContext';
import SkywiseCrown from '../Shared/SkywiseCrown/SkywiseCrown';

type mpdRepositoryAnalysisProps = {
  buildName: string;
  createdOn?: string;
  updatedOn?: string;
  exportedOn?:string;
  isExportButtonEnabled?: boolean;
  requestStatus: string;
  id: string;
  // polling the Program Analysis results if any rebuild happens
  pollingCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPollingCount?: any;
  isAnnex13Impacted ?: boolean
};

const MpdRepositoryAnalysisCard = (props: mpdRepositoryAnalysisProps) => {
  const operator = secureLS.get('operator');
  const acProgram = secureLS.get('ac_program');
  const {
    buildName, createdOn, updatedOn, exportedOn, isExportButtonEnabled, requestStatus, id, pollingCount, setPollingCount, isAnnex13Impacted,
  } = props;
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [cardTitle, setCardTitle] = useState(buildName);
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const { userType } = useContext(appContext);
  // after demo add this check with AND condition && (checkPermissionByType(PROGRAM_ANALYSIS_MODULE, PREMIUM_PERMISSION)
  const isPremium = featureToggleConfig({ name: 'ExportToSharedSpace', userType }, true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pollingIntervalRef = useRef<any>();

  const navigate = useNavigate();
  // helper function to disable/enable retyr button based on timesec
  const handleDisableTimeout = ({ disableVal, timeSec }: { disableVal: boolean, timeSec: number }) => {
    setTimeout(() => {
      setIsRetryDisabled(disableVal);
    }, timeSec);
  };

  const handleOpen = () => {
    setOpen(true);
    setPollingCount(1);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editTitle = () => {
    setIsEditing(false);
    if (cardTitle !== buildName) {
      dispatch(updateTitle(id, cardTitle)({ id, cardTitle }));
    }
  };

  const goToResults = () => {
    navigate(`/program-analysis/${id}`);
  };

  const deleteBuild = async () => {
    // on click of delete button in modal pop up closing the modal and pushing a toast notification
    setOpen(false);
    // disabling rebuild after delete
    handleDisableTimeout({ disableVal: true, timeSec: 200 });
    dispatch(
      createToast({
        id,
        cssClass: 'repo-tost-cls',
        variant: undefined,
        title: 'Deleting',
        content: [buildName],
      }),
    );
    const clearAndAddNewBanner = (banner: BannerType) => {
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(addBanner({
        ...banner,
        bannerMessage: banner.bannerMessage.replace('`<FILENAME>`', `"${cardTitle}"`),
      }));
    };

    try {
      setIsDeleteDisabled(true);
      dispatch(clearBuild());
      await dispatch(deleteBuildData(id)({ id })).unwrap();
      clearAndAddNewBanner(REPO_FILE_DELETE_SUCCESS_PA);
    } catch (error) {
      clearAndAddNewBanner(REPO_FILE_DELETE_FAILED_PA);
    }
  };

  const retryBuild = () => {
    setPollingCount(1);
    dispatch(retryBuildData(id)({ id }));
    setIsRetryDisabled(true);
    // Enable back the Re-build button after 15 seconds of re-build
    handleDisableTimeout({ disableVal: false, timeSec: 30000 });
  };

  const handleExport = async () => {
    // set polling to avoid spinner from buildlist container
    setPollingCount(11);
    // export button state to disable it after click
    setIsExportDisabled(true);
    // enabling export button state after 15 seconds after click
    setTimeout(() => {
      setIsExportDisabled(false);
    }, 15000);
    try {
      dispatch(
        createToast({
          id,
          cssClass: 'repo-tost-cls',
          variant: undefined,
          title: 'Exporting',
          content: [buildName],
        }),
      );
      await dispatch(exportDataToSharedSpace(id)({ id }));
      await dispatch(fetchPARepositoryData(operator, acProgram)());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Export failed: ', error);
    }
  };

  /* function for Export to Skywise button based on user type */
  const exportButton = () => {
    if (isPremium) {
      // Button will be disabled when Export in Porgress for Premium user
      if (!isExportButtonEnabled) {
        return (
          <Tooltip label="Export In Progress" placement="top">
            <MenuButton
              aria-label="exportbtn"
              variant="secondary"
              className="export-btn-cls"
              size="xsmall"
              disabled
            >
              Export to Skywise
              <SkywiseCrown />
            </MenuButton>
          </Tooltip>
        );
      }
      return (
        <MenuButton
          aria-label="exportbtn"
          variant="secondary"
          className="export-btn-cls"
          size="xsmall"
          disabled={((isExportDisabled) || (isDeleteDisabled || isRetryDisabled || [
            POSSIBLE_BUILD_STATUS.IN_PROGRESS,
            POSSIBLE_BUILD_STATUS.INIT,
            POSSIBLE_BUILD_STATUS.UPDATE_INIT, POSSIBLE_BUILD_STATUS.FAILED,
          ].includes(requestStatus)))}
          onClick={handleExport}
        >
          Export to Skywise
          <SkywiseCrown />
        </MenuButton>
      );
    }
    // for Non-premium button will be Disabled
    return (
      <Tooltip label="Available for Premium accounts" placement="top">
        <MenuButton
          aria-label="exportbtn"
          variant="secondary"
          className="export-btn-cls"
          icon={<LockOutline />}
          size="xsmall"
          disabled
        >
          Export to Skywise
          <SkywiseCrown />
        </MenuButton>
      </Tooltip>
    );
  };
  useEffect(() => {
    // polling fetchPARepositoryData Api
    // if all mpdcards status is not in [INIT or IN_PROGRESS or UPDATE_INIT] status polling stops else it polls maximum 10 times
    if (([POSSIBLE_BUILD_STATUS.INIT, POSSIBLE_BUILD_STATUS.IN_PROGRESS, POSSIBLE_BUILD_STATUS.UPDATE_INIT].includes(requestStatus)) && (pollingCount != null && pollingCount < 11)) {
      pollingIntervalRef.current = setInterval(() => {
        // eslint-disable-next-line no-unused-expressions
        (pollingCount != null) && setPollingCount(pollingCount + 1);
        dispatch(fetchPARepositoryData(operator, acProgram)());
      }, RebuildPollingInterval);
    }
    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [requestStatus, acProgram, operator, dispatch, pollingCount, setPollingCount]);

  const analysisCardMenuBtnHandler = () => {
    if (requestStatus === POSSIBLE_BUILD_STATUS.FAILED) {
      return (
        <MenuButton
          aria-label="retryBuild"
          variant="ghost"
          size="xsmall"
          className="action-btn-cls"
          icon={<Refresh />}
          onClick={retryBuild}
          disabled={isRetryDisabled}
        >
          Retry Submit
        </MenuButton>
      );
    }
    if (requestStatus === POSSIBLE_BUILD_STATUS.READY) {
      return (
        <div className="button-newArc">
          <MenuButton
            aria-label="reBuild"
            variant="ghost"
            className="rebuild"
            id={id}
            size="xsmall"
            icon={<Sync />}
            onClick={() => {
              retryBuild();
              dispatch(
                createToast({
                  id,
                  cssClass: 'repo-tost-cls',
                  variant: undefined,
                  title: ' Update data in progress',
                  content: [buildName],
                }),
              );
            }}
            disabled={isRetryDisabled}
          >
            Update
          </MenuButton>
          <MenuButton
            aria-label="goToResult"
            variant="primary"
            className="action-btn-cls"
            onClick={goToResults}
            size="xsmall"
          >
            View
          </MenuButton>
          {exportButton()}
        </div>
      );
    }
    return (
      <>
        <MenuButton
          aria-label="goToResult"
          variant="primary"
          className="action-btn-cls"
          size="xsmall"
          onClick={goToResults}
        >
          View
        </MenuButton>
        {exportButton()}
      </>
    );
  };

  const deleteMessage = (
    <p>
      {ANALYSIS_DELETE_MESSAGE}
      <br />
      <b>{buildName}</b>
    </p>
  );

  const renderNewArchitectureMpdCard = () => {
    return (
      <div className="mpd-repository-analysis-card">
        <div className="left-panel-newArc">
          <div>
            <div className="title">
              {isEditing ? (
                <>
                  <Input
                    value={cardTitle}
                    size="small"
                    aria-label="name-input"
                    onChange={(_, val) => setCardTitle(val)}
                    // maximum 60 characters allowed for title
                    maxLength={60}
                  />
                  <span>
                    <IconButton
                      variant="ghost"
                      aria-label="validate"
                      size="small"
                      onClick={editTitle}
                    >
                      <Check />
                    </IconButton>
                  </span>
                </>
              ) : (
                <>
                  <p>
                    <b>{cardTitle}</b>
                  </p>
                  <span>
                    <IconButton
                      variant="ghost"
                      aria-label="edit"
                      size="small"
                      onClick={() => setIsEditing(true)}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </>
              )}
            </div>
            <div className="details">
              <div className="card_content">
                <p className="status">
                  {requestStatus === POSSIBLE_BUILD_STATUS.READY && !isAnnex13Impacted && (
                    <Chip variant="success" className="status-chip">
                      <b>Available</b>
                    </Chip>
                  )}
                  {requestStatus === POSSIBLE_BUILD_STATUS.READY && isAnnex13Impacted && (
                    <Chip variant="error" className="status-chip">
                      <b>Impacted by Annex-13</b>
                    </Chip>
                  )}
                  {[
                    POSSIBLE_BUILD_STATUS.IN_PROGRESS,
                    POSSIBLE_BUILD_STATUS.INIT,
                    POSSIBLE_BUILD_STATUS.UPDATE_INIT,
                  ].includes(requestStatus) && (
                    <Chip variant="warning" className="status-chip">
                      <b>In progress</b>
                    </Chip>
                  )}
                  {requestStatus === POSSIBLE_BUILD_STATUS.FAILED && (
                    <Chip variant="error" className="status-chip">
                      <b>Failed</b>
                    </Chip>
                  )}
                </p>
                <div className="creation-updation-time">
                  <span>Created on </span>
                  {createdOn ? `${formatRepositoryDate(createdOn)}` : ''}
                </div>
                { createdOn !== updatedOn && (
                <div className="creation-updation-time">
                  <span>Updated on </span>
                  {updatedOn ? `${formatRepositoryDate(updatedOn)}` : ''}
                </div>
                )}
                {exportedOn && (
                <div className="creation-updation-time">
                  <span>Exported on </span>
                  { `${formatRepositoryDate(exportedOn)}`}
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="right-panel-newArc">
            { !isAnnex13Impacted && analysisCardMenuBtnHandler()}
            <IconButton
              aria-label="deleteBuild"
              className="delete-btn-cls"
              size="xsmall"
              onClick={handleOpen}
              disabled={isDeleteDisabled || isRetryDisabled || [
                POSSIBLE_BUILD_STATUS.IN_PROGRESS,
                POSSIBLE_BUILD_STATUS.INIT,
                POSSIBLE_BUILD_STATUS.UPDATE_INIT,
              ].includes(requestStatus)}
            >
              <Delete />
            </IconButton>
          </div>

          <div />
        </div>
        <DialogComponent
          message={deleteMessage}
          dialogTitle={ANALYSIS_DELETE_TITLE}
          primmaryButtonName={MPD_DELETE_PRIMARY_BUTTON_NAME}
          secondaryButtonName={MPD_DELETE_SECONDARY_BUTTON_NAME}
          open={open}
          onClose={handleClose}
          onSubmit={deleteBuild}
        />
      </div>
    );
  };

  return (
    <div>
      {renderNewArchitectureMpdCard()}
    </div>
  );
};
export default MpdRepositoryAnalysisCard;

MpdRepositoryAnalysisCard.defaultProps = {
  createdOn: '',
  updatedOn: '',
  exportedOn: '',
  isExportButtonEnabled: true,
  pollingCount: 0,
  isAnnex13Impacted: false,
  setPollingCount: () => {},
};
