import { initialState } from './MpdGeneralInfoSlice';

export const updateMpdGenInfoFulfilled = (state: MpdGeneralInfoState, action: MpdGenInfoAction) => ({
  ...state,
  MpdGeneralInfoIssueData: [
    {
      title: 'Issue',
      value: action.payload.data.Issue,
    },
    {
      title: 'Updated on',
      value: action.payload.data['Issue Date'],
    },
  ],
  MpdGeneralInfoEstablishedFrom: [
    {
      title: 'MPD Revision',
      value: action.payload.data['MPD Revision'],
    },
    {
      title: 'Issue Number',
      value: action.payload.data['Issue Number'],
    },
    {
      title: 'Revision Date',
      value: action.payload.data['Revision Date'],
    },
    {
      title: 'Issue Date',
      value: action.payload.data['MPD Issue Date'],
    },
  ],
});

export const updateMpdGenInfoRejected = (state: MpdGeneralInfoState, action: emtlGeneralInfoRejectedAction) => ({
  ...state,
  MpdGeneralInfoIssueData: initialState.MpdGeneralInfoIssueData,
  MpdGeneralInfoEstablishedFrom: initialState.MpdGeneralInfoEstablishedFrom,
  error: action.payload || action.error?.message,
});
