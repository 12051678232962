/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, Tooltip } from '@airbus/components-react';
import { useAppSelector } from '../../../../store/hooksTypes';
import { RootState } from '../../../../store/store';
import '../../../Shared/DataTable/DataTableCell.scss';
import './MpdTableAppendixCell.scss';
import MpdCustomizationTablePopup from '../../../MpdCustomizationTablePopup/MpdCustomizationTablePopup';
import DataTableCell from '../../../Shared/DataTable/DataTableCell';

interface MpdTableAppendixCellProps {
  mpdTaskNumber: string;
  cellValue: string;
}

/**
 * Component to represent a Appendix3 cell in the DataTable component.
 * Data greater than a predefined length can be hidden/expanded using a show more/show less button.
 * @param {string} mpdTaskNumber Value need to get appendix table value
 * @param {string} cellValue Value to be displayed in cell
 */

const MpdTableAppendixCell: React.FC<MpdTableAppendixCellProps> = function MpdTableAppendixCell(props: MpdTableAppendixCellProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { mpdTaskNumber, cellValue } = props;
  const {
    mpdTableDataRowFilteredDataType: emtlTableDataRowFiltered,
  } = useAppSelector((state: RootState) => state.mpdTable);

  const getAppendixElement = (element: mpdTask) => element.mpd_task_reference === mpdTaskNumber;
  const appendix3Data: mpdTask = emtlTableDataRowFiltered[emtlTableDataRowFiltered.findIndex(getAppendixElement)];
  const index = cellValue.indexOf('CE NOTE');

  return (appendix3Data?.appendix === undefined
    ? <DataTableCell maxLength={100} maxLines={6} cellValue={cellValue} />
    : (
      <div className="data-table-cell-cls mpd-table-cell-cls">
        <MpdCustomizationTablePopup open={open} onClose={handleClose} data={appendix3Data.appendix} />
        <p>{cellValue.substring(0, (index - 1))}</p>
        <Tooltip large placement="bottom" className="tooltip-mpd-data-cell" label="View Customizable Equipment">
          <Link
            className="data-table-cell-link"
            variant="underlined"
            aria-label="ce-note"
            onClick={() => {
              handleOpen();
            }}
          >
            {cellValue.substring(index, (index + 7))}
          </Link>
        </Tooltip>
        <p>{index + 8 === cellValue.length - 1 && cellValue.substring((index + 8), (cellValue.length - 1))}</p>
      </div>
    )
  );
};

export default MpdTableAppendixCell;
