export const REPO_CONSTANTS = {
  emptyContentIconName: 'AssignmentLateOutline',
  noExportedFiles: 'No exported files available',
  noExportedFilesSubTitle: 'You can export task list from program customization',
  noDeltaFiles: 'No delta files available',
  noDeltaFilesSubTitle: 'You can create delta files from EMTL',
  noDataForFilterIcon: 'WarningOutline',
  noDataForFilter: 'No results found',
  noDataForFilterSubTitle: 'Try adjusting the search or filter to find what you are looking for',
  noDeltaSocTitle: 'Changes not found',
  noDeltaSocSubtitle: 'No changes found with respect to reference file',
};

export const REPO_MODULE_NAME_MAPPING = {
  EMTL: {
    repoExportListTitle: 'EMTL',
    repoExportListTitleVal: 'EMTL',
    repoExportListDeltaTitle: 'EMTL Delta',
    repoExportListDeltaTitleVal: 'EMTL_DELTA',
  },
  OTR: {
    repoExportListTitle: 'Other Sources',
    repoExportListTitleVal: 'OTR',
    repoExportListDeltaTitle: 'Other Sources Delta',
    repoExportListDeltaTitleVal: 'OTR_DELTA',
  },
  '': {
    repoExportListTitle: '',
    repoExportListTitleVal: '',
    repoExportListDeltaTitle: '',
    repoExportListDeltaTitleVal: '',
  },
};
