import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Header as AppBar,
  Tabs,
  Tab,
  IconButton,
  Badge,
  Button,
  Popover,
} from '@airbus/components-react';
import {
  Notification,
  Help,
  ExitToApp,
  Chevrondown,
} from '@airbus/icons/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import headerTabs from '../../../__mocks__/AppHeader/headerTabs';
import './AppHeader.scss';
import { appContext } from '../../../utils/context/userContext';
import { featureSwitchConfig } from '../../../utils/FeatureToggleUtil/FeatureToggleUtil';
import NotificationComponent from '../../NotificationComponent/NotificationComponent';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { fetchNotificationData, updateNotificationsAsRead } from '../../../models/BellNotificationModel/BellNotificationAsyncThunk';
import { clearNotificationData } from '../../../models/BellNotificationModel/BellNotificationSlice';
import { RootState } from '../../../store/store';
import HelpIconComponent from '../../HelpCenterComponent/HelpCenterComponent';

const NOTIFICATION_LIMIT = 5;
const NOTIFICATION_OFFSET = 0;
interface dataProps {
  appName: string;
}

const AppHeader: React.FC<dataProps> = function AppHeader(props: dataProps) {
  const { appName } = props;
  const { userType } = useContext(appContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [givenName, setGivenName] = useState('');
  const [preferredName, setPrefferedName] = useState('');
  const [loading, setLoading] = useState(true);
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
  const [isNotificationEnable, setIsNotificationEnable] = useState(false);
  const [open, setOpen] = useState(false);
  const [btnNode, setBtnNode] = useState<HTMLElement | null>();
  const [notifReadInProgress, setNotifReadInProgress] = useState(false);

  const newRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLDivElement>(null);

  const { features } = useAppSelector((state: RootState) => state.featureToggle);
  const { notifications, unreadCount } = useAppSelector((state: RootState) => state.BellNotification);

  const handleOutsideClickEvent = (event: MouseEvent) => {
    if (newRef.current && !newRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((amplifyUser) => {
        setGivenName(amplifyUser.attributes.given_name);
        setPrefferedName(amplifyUser.attributes.preferred_username || amplifyUser.attributes.family_name);
        setLoading(false);
      }).catch(() => { });
    document.addEventListener('mousedown', handleOutsideClickEvent);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClickEvent);
    };
  }, []);
  useEffect(() => {
    const currNav = headerTabs.find((navItem) => location.pathname.includes(navItem.routeTo) && navItem.routeTo !== '/');
    setTabSelectedIndex(currNav ? currNav.id : 0);
  }, [location]);

  useEffect(() => {
    // 'features' is added since it should run only after 'featureToggleList' API comes.
    // Remove 'features' once feature-toggle is removed from it.
    if (userType && features) {
      const toggledBellNotification = featureSwitchConfig(
        { name: 'bellNotificationFeature', userType },
        () => dispatch(fetchNotificationData(NOTIFICATION_OFFSET, NOTIFICATION_LIMIT)()),
        () => null,
      );
      toggledBellNotification();
    }
  }, [dispatch, userType, features]);

  // feature toggle for internal & external users userguide url
  const renderHelpView = featureSwitchConfig(
    { name: 'userGuideHelpView', userType },
    () => (
      <HelpIconComponent />
    ),
    () => <IconButton variant="ghost"><Help /></IconButton>,
  );

  const handleNotificationsAsRead = () => {
    if (!notifReadInProgress && unreadCount) {
      setNotifReadInProgress(true);
      const notificationsToMarkRead = notifications.filter((notification) => notification.unread).map((notification) => notification.notificationId);

      dispatch(updateNotificationsAsRead(notificationsToMarkRead)()).unwrap()
        .then((results: { message: string }) => {
          if (results) {
            dispatch(clearNotificationData());
            dispatch(fetchNotificationData(NOTIFICATION_OFFSET, NOTIFICATION_LIMIT)());
          }
        })
        .catch(() => { })
        .finally(() => setNotifReadInProgress(false));
    }
  };

  // Checks if a click event occurred outside of the referenced element
  const isClickOutsideBell = (target: EventTarget | null): boolean => {
    return bellIconRef.current ? !bellIconRef.current.contains(target as Node) : false;
  };

  const handleBellIconToggle = () => {
    // Handling previous state
    if (isNotificationEnable) {
      handleNotificationsAsRead();
    }

    setIsNotificationEnable(!isNotificationEnable);
  };

  const handleNotificationClose = () => {
    setIsNotificationEnable(false);
    handleNotificationsAsRead();
  };

  const calculatePopupRightPosition = () => {
    const rect = bellIconRef?.current?.getBoundingClientRect();
    return window.innerWidth - (rect?.right || 0);
  };

  const showNotificationBadge = unreadCount > 0 && !isNotificationEnable && !notifReadInProgress;
  const notificationWithBadge = (
    <div ref={bellIconRef}>
      <IconButton variant="ghost" aria-label="Notifications-Count" onClick={handleBellIconToggle}>
        <Notification />
        {showNotificationBadge && <Badge count={unreadCount} variant="error" className="show-badge" />}
      </IconButton>
    </div>
  );

  const notificationWithoutBadge = (
    <IconButton variant="ghost" aria-label="Notifications">
      <Notification />
    </IconButton>
  );
  // feature flipping for enable bell Icon
  const renderNotificationBellIcon = featureSwitchConfig(
    { name: 'bellNotificationFeature', userType },
    notificationWithBadge,
    notificationWithoutBadge,
  );

  const renderTabs = () => (
    <Tabs value={tabSelectedIndex}>
      {headerTabs.map((headerTab) => (
        <Tab
          key={headerTab.id}
          value={headerTab.id}
          onClick={() => navigate(headerTab.routeTo, {
            state: location.pathname,
          })}
          aria-label="HeaderNav"
          disabled={headerTab.disabled}
        >
          {headerTab.title}
        </Tab>
      ))}
    </Tabs>
  );
  return (
    <div className="exclude-print header-cls app-header-cls">
      <AppBar appName={appName}>
        {location.pathname !== '/' && renderTabs()}
        {renderNotificationBellIcon}
        {renderHelpView()}
        {loading ? (
          <SpinnerComponent size="small" label="" />
        ) : (
          <>
            <Button
              ref={(node) => {
                setBtnNode(node);
              }}
              variant="primary"
              className="profile-btn-cls"
              onClick={() => setOpen(!open)}
            >
              {`${givenName} ${preferredName}`}
              <Chevrondown className="chevron-style-cls" />
            </Button>
            <Popover
              ref={newRef}
              open={open}
              referenceNode={btnNode}
              arrow={false}
              style={{
                backgroundColor: 'white',
                top: '58px',
                right: '22px',
                bottom: 'auto',
                left: 'auto',
                transform: 'translate3d(0vw, 0vh, 0px)',
              }}
            >
              <div style={{ width: '11rem' }}>
                <Button variant="primary" onClick={() => navigate('/signout')}>
                  <ExitToApp />
                  Logout
                </Button>
              </div>
            </Popover>
          </>
        )}
      </AppBar>
      {isNotificationEnable && (
        <div
          className="notification-container"
          style={{ right: calculatePopupRightPosition() }}
        >
          <NotificationComponent
            handleNotificationClose={handleNotificationClose}
            isClickOutsideBell={isClickOutsideBell}
          />
        </div>
      )}
    </div>
  );
};

export default AppHeader;
