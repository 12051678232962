import { createSlice } from '@reduxjs/toolkit';
import {
  updateMpdGenInfoRejected,
  updateMpdGenInfoFulfilled,
} from './MpdGeneralInfoReducers';
import { createMpdGeneralInfo } from './MpdGeneralInfoAsyncThunk';
import { MPD_GEN_INFO_SLICE } from './constants';

export const initialState: MpdGeneralInfoState = {
  MpdGeneralInfoIssueData: [
    {
      title: 'Issue',
      value: '',
    },
    {
      title: 'Updated on',
      value: '',
    },
  ],
  MpdGeneralInfoEstablishedFrom: [
    {
      title: 'MPD Revision',
      value: '',
    },
    {
      title: 'Issue Number',
      value: '',
    },
    {
      title: 'Revision Date',
      value: '',
    },
    {
      title: 'Issue Date',
      value: '',
    },
  ],
  error: '',
};

const MpdGeneralInfoSlice = createSlice({
  name: MPD_GEN_INFO_SLICE,
  initialState,
  reducers: {
    // sync action creators
  },
  // Async action creators
  extraReducers: (builder) => {
    builder.addCase(createMpdGeneralInfo().fulfilled, updateMpdGenInfoFulfilled);
    builder.addCase(createMpdGeneralInfo().rejected, updateMpdGenInfoRejected);
  },
});

export default MpdGeneralInfoSlice;
