/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CREATE_MPD_REPOSITORY_TAST_LIST_EXPORT, FETCH_MPD_REPOSITORY_TAST_LIST_API_PATH, FETCH_MPD_REPOSITORY_TAST_LIST_EXPORT,
  FETCH_MPD_REPOSITORY_EXPORT_FILE, CREATE_MPD_REPOSITORY_EXPORT_FILE, FETCH_MPD_REPOSITORY_EXPORT_FILE_API_PATH,
  FETCH_MPD_REPOSITORY_FILTER,
  CREATE_MPD_REPOSITORY_FILTER,
  FETCH_MPD_REPOSITORY_FILTER_API_PATH,
  FETCH_MPD_REPOSITORY_DELETE_API_PATH,
  FETCH_MPD_REPOSITORY_DELETE,
  CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE,
  CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE_PATH,
  FETCH_MPD_REPOSITORY_DELTA_VIEW,
  CREATE_MPD_REPOSITORY_DELTA_VIEW,
  CREATE_MPD_REPOSITORY_DELTA_VIEW_PATH,
  FETCH_MPD_REPOSITORY_DELTA_VIEW_FILTER,
  CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER,
  CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER_PATH,
  CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_PATH,
  FETCH_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA,
  CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA,
  CREATE_MPD_REPOSITORY_PREVIOUS_REVISION,
  FETCH_MPD_REPOSITORY_PREVIOUS_REVISION,
  FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_PATH,
  FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK,
  CREATE_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';
import { MPD_TABLE_EXPORT_TASK_API_PATH } from '../mpdEmtlTableModel/constants';

export const createRepositoryTaskList: any = (pageSize: number, pageNumber: number, acProgram: string, filterGroup: [], tab: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_TAST_LIST_EXPORT,
    actionType: CREATE_MPD_REPOSITORY_TAST_LIST_EXPORT,
  };
  const apiRequestObj = {
    path: `${FETCH_MPD_REPOSITORY_TAST_LIST_API_PATH}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgram,
      customization_module: tab,
      filter_columns: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createRepoExportTask: any = (fileKey: string, fileType: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_EXPORT_FILE,
    actionType: CREATE_MPD_REPOSITORY_EXPORT_FILE,
  };
  const apiRequestObj = {
    path: `${FETCH_MPD_REPOSITORY_EXPORT_FILE_API_PATH}?export-task-id=${fileKey}&file-type=${fileType}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createRepoDeltaTableView: any = (hashKey: string, pageNumber: number, pageSize: number, filterGroup: Array<{ filter_column: string, filter_value: string }>) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_DELTA_VIEW,
    actionType: CREATE_MPD_REPOSITORY_DELTA_VIEW,
  };

  const apiRequestObj = {
    path: `${CREATE_MPD_REPOSITORY_DELTA_VIEW_PATH}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    method: 'post' as Methods,
    body: {
      hash_key: hashKey,
      filter_group: filterGroup,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createRepoDeltaTableViewFilter: any = (hashKey: string, filterGroup: repositoryFilterGroup) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_DELTA_VIEW_FILTER,
    actionType: CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER,
  };

  const apiRequestObj = {
    path: CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER_PATH,
    method: 'post' as Methods,
    body: {
      hash_key: hashKey,
      filter_group: filterGroup,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdRepositoryFilter: any = (acProgram: string, moduleName: string, columnName: string, searchKey: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_FILTER,
    actionType: CREATE_MPD_REPOSITORY_FILTER,
  };
  const apiRequestObj = {
    path: FETCH_MPD_REPOSITORY_FILTER_API_PATH,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgram,
      customization_module: moduleName,
      column_name: columnName,
      column_value: searchKey,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdRepositoryDelete: any = (filterModuleVersion: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_DELETE,
    actionType: FETCH_MPD_REPOSITORY_DELETE_API_PATH,
  };
  const apiRequestObj = {
    path: `${FETCH_MPD_REPOSITORY_DELETE_API_PATH}?export-task-id=${filterModuleVersion}`,
    method: 'del' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createRepoCompareDeltaTask: any = (moduleName: string, acProgram: string, referenceFilterModuleVersion: string, compareFilterModuleVersion: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_TAST_LIST_EXPORT,
    actionType: CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE,
  };

  const apiRequestObj = {
    path: CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE_PATH,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgram,
      customization_module: moduleName,
      first_task_id: referenceFilterModuleVersion,
      second_task_id: compareFilterModuleVersion,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createViewGeneralInfoData: any = (hashKey: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA,
    actionType: CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA,
  };

  const apiRequestObj = {
    path: `${CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_PATH}?hash_key=${hashKey}`,
    method: 'get' as Methods,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createPreviousRevisionData: any = (customizationModule: string, revNo: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_PREVIOUS_REVISION,
    actionType: CREATE_MPD_REPOSITORY_PREVIOUS_REVISION,
  };

  const apiRequestObj = {
    path: `${FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_PATH}?customization_module=${customizationModule}&rev_no=${revNo}`,
    method: 'get' as Methods,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createPreviousRevisionExportTask: any = (customizationModule: string) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK,
    actionType: CREATE_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK,
  };

  const apiRequestObj = {
    path: MPD_TABLE_EXPORT_TASK_API_PATH,
    method: 'post' as Methods,
    body: {
      customizationModule,
      revisionHistory: true,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
