import { Download, Error } from '@airbus/icons/react';

// Slice Name
export const BANNER_SLICE = 'banner';

// To map custom banner icon names
export const customBannerIconMap: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  DOWNLOAD: Download,
  ERROR: Error,
};

// To map location names
export const bannerLocationMap = {
  GLOBAL: 'global',
  PROGRAM_CUSTOMISATION: 'program-customisation',
  TA_TABLES: 'ta-tables',
  REPOSITORY_MODULE: 'repository-module',
  PROGRAM_ANALYSIS: 'program-analysis',
  UNSCHEDULED_EVENTS: 'unscheduled-events',
};

// Banner message constants
export const EFFECTIVITY_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.PROGRAM_CUSTOMISATION,
  bannerMessage: 'Disclaimer: Effectivity is calculated from airline configuration as reported to Airbus. Refresh of effectivity is done every week on Wednesday.',
  bannerVariantType: 'warning',
};
export const NO_DATA_FOUND: BannerType = {
  bannerLocation: bannerLocationMap.PROGRAM_CUSTOMISATION,
  bannerMessage: 'No data to display for current selection.',
  bannerVariantType: 'warning',
};
export const UNSCHEDULED_EVENTS_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.UNSCHEDULED_EVENTS,
  bannerMessage: 'Disclaimer : This analysis is not relevant for selected task.',
  bannerVariantType: 'warning',
};
export const LSDR_TASK_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.PROGRAM_ANALYSIS,
  bannerMessage: 'Disclaimer : For Discard, Lubrication, Restoration and Servicing tasks, the calculation of finding rate is not applicable since these tasks are not intended to generate findings.',
  bannerVariantType: 'warning',
};
export const CSAF_SOURCE_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.PROGRAM_ANALYSIS,
  bannerMessage: 'Disclaimer : This analysis is not applicable for pure ALI, ALI WFD, CMR, SEMR, and FAL tasks.',
  bannerVariantType: 'warning',
};
export const RAW_DATA_TABLE_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.TA_TABLES,
  bannerMessage: 'Disclaimer: Only upto 100K records are shown in the table',
  bannerVariantType: 'warning',
};
export const LOADING_RAW_DATA_TABLE_DISCLAIMER: BannerType = {
  bannerLocation: bannerLocationMap.TA_TABLES,
  bannerMessage: 'Disclaimer: Download in progress. Please wait ...',
  bannerVariantType: 'warning',
};
export const EXPORT_IN_PROGRESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Your file is being exported, please wait for a few seconds.',
  bannerVariantType: 'success',
  customBannerIcon: 'DOWNLOAD',
};
export const EXPORT_SUCCESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'The task list is exported successfully.',
  bannerVariantType: 'success',
};
export const EXPORT_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'The file `<FILENAME>` could not be exported.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};
export const REPO_EXPORT_DOWNLOAD_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The file was not downloaded.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};
export const DELTA_DATA_LOAD_ERROR: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'An error occured.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};
export const COMPARE_DOWNLOAD_IN_PROGRESS: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'Your comparison file is being downloaded, please wait for a few seconds.',
  bannerVariantType: 'success',
  customBannerIcon: 'DOWNLOAD',
};

export const COMPARE_DOWNLOAD_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The comparison file for `<FILENAME1>` and `<FILENAME2>` could not be downloaded.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const COMPARE_CREATION_IN_PROGRESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Comparison file for `<FILENAME1>` & `<FILENAME2>` is being created, please wait for a few seconds.',
  bannerVariantType: 'success',
  customBannerIcon: 'DOWNLOAD',
};
export const COMPARE_CREATION_SUCCESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Comparison file for `<FILENAME1>` & `<FILENAME2>` is created successfully.',
  bannerVariantType: 'success',
};
export const COMPARE_CREATION_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Comparison file for `<FILENAME1>` & `<FILENAME2>` was not created.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const REPO_FILE_DELETE_SUCCESS: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The file `<FILENAME>` was successfully deleted.',
  bannerVariantType: 'success',
};

export const REPO_COMPARE_FILE_DELETE_SUCCESS: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'Comparison file for `<FILENAME>` was successfully deleted.',
  bannerVariantType: 'success',
};

export const REPO_FILE_DELETE_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The file `<FILENAME>` was not deleted.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const REPO_COMPARE_FILE_DELETE_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'Comparison file for `<FILENAME>` was not deleted.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

// creating seperate banner constant for PA repo module
export const REPO_FILE_DELETE_SUCCESS_PA: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The Analysis `<FILENAME>` was successfully deleted.',
  bannerVariantType: 'success',
};

export const REPO_FILE_DELETE_FAILED_PA: BannerType = {
  bannerLocation: bannerLocationMap.REPOSITORY_MODULE,
  bannerMessage: 'The Analysis `<FILENAME>` was not deleted.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const PROG_CUST_EXPORT_DOWNLOAD_IN_PROGRESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Your file is being downloaded. Please wait a moment.',
  bannerVariantType: 'success',
  customBannerIcon: 'DOWNLOAD',
};

export const PROG_CUST_EXPORT_DOWNLOAD_SUCCESS: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'The task list has been downloaded successfully.',
  bannerVariantType: 'success',
};

export const PROG_CUST_EXPORT_DOWNLOAD_FAILED: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: ' The file `<FILENAME>` could not be downloaded. Please check your connection or try again later.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const PROG_CUST_EXPORT_DOWNLOAD_TIMEOUT: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'There was an issue with the download. Please try again later.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};

export const PROG_CUST_EXPORT_DOWNLOAD_ERROR: BannerType = {
  bannerLocation: bannerLocationMap.GLOBAL,
  bannerMessage: 'Error: <ERROR>. Please try again later.',
  bannerVariantType: 'error',
  customBannerIcon: 'ERROR',
};
